import React, { useState, useEffect } from 'react'
import './Celebration.css'
import PhotoGalleria from '../../utils/PhotoGalleria'
import { galleriaCelebration } from '../../constants/ImportPhoto'

let timeSet = 1000
let timeSet01 = 1000
export default function Celebration() {
  const [scene, setScene] = useState('Before PhotoClub')
  const [stateTimer, setStateTimer] = useState(1)
  const [scene01, setScene01] = useState('Before PhotoClub')
  const [stateTimer01, setStateTimer01] = useState(7)
  // useEffect(() => {
  // const timeout = setTimeout(() => console.log(stateTimer), 20)
  // return () => clearTimeout(timeout)
  // }, [stateTimer])
  useEffect(() => {
    setTimeout(() => {
      let photoFrame = 'PhotoCelebration01'
      console.log(stateTimer)
      setStateTimer(stateTimer + 1)
      if (stateTimer < 7) photoFrame = 'PhotoCelebration'
      if (stateTimer === 6) timeSet = 5000
      if (stateTimer === 5) timeSet = 2000
      setScene(`Step${stateTimer} ${photoFrame}`)
      if (stateTimer === 6) setStateTimer(1)
    }, timeSet)
    timeSet = 1000
  }, [stateTimer])
  useEffect(() => {
  setTimeout(() => {
    let photoFrame = 'PhotoCelebration01'
    console.log(stateTimer01)
    setStateTimer01(stateTimer01 + 1)
    if ((stateTimer01 > 12) & (stateTimer01 < 19))
      photoFrame = 'PhotoCelebration02'
    if (stateTimer01 > 18) photoFrame = 'PhotoCelebration03'
    if (
      stateTimer01 === 9 ||
      stateTimer01 === 12 ||
      stateTimer01 === 15 ||
      stateTimer01 === 18 ||
      stateTimer01 === 21
    )
      timeSet01 = 5000
    if (stateTimer01 === 5) timeSet01 = 2000
    setScene01(`Step${stateTimer01} ${photoFrame}`)
    if (stateTimer01 === 21) setStateTimer01(7)
  }, timeSet01)
  timeSet01 = 1000
  }, [stateTimer01])

  return (
    <div className="Celebration-header">
      <div className="Celebration_Сell">
        <div className="Description">
          <h1>
            Фотосъемка с переносным студийным светом в ресторане — идеальное
            решение для вашего праздника!
          </h1>
          <h2>
            Хотите получить профессиональные фотографии, не покидая уютной
            атмосферы ресторана? Предлагаю вам фотосъемку с переносным студийным
            светом, которая сочетает в себе преимущества студийного качества и
            комфорт вашего мероприятия. Привезу студийное оборудование прямо в
            ресторан, никто не отвлекается от праздника — съемка проходит быстро
            и удобно, профессиональное оборудование гарантирует безупречное
            качество кадров, даже в условиях слабого освещения
          </h2>
          <h2>
            Закажите фотосъемку прямо сейчас - сделайте оригинальный подарок без
            лишних хлопот! Акционная цена услуги 95 бел. рублей. Специальное
            предложение: при заказе фотосъемки — скидка 10% на <a href="/">все пакеты услуг!</a>
          </h2>
        </div>
        <div className="Header PhotoCelebration"></div>
        <div className="Description">
          <h1>
            Создайте идеальную групповую фотографию без стресса и компромиссов!
          </h1>
          <h2>
            Хотите получить безупречную групповую фотографию, где каждый
            выглядит идеально? Предлагаю уникальную услугу: фотосъемку каждого
            гостя или группы гостей по отдельности с последующим созданием общей
            фотографии на этапе постобработки. Это современное решение, которое
            избавит вас от привычных проблем групповой съемки.
          </h2>
        </div>
        <div className={scene}></div>
        {/* <h2>{stateTimer}</h2> */}
        <div className="Description">
          <h1>Как это работает?</h1>
          <h2>
            Каждый гость или группа гостей фотографируется отдельно, что
            позволяет выбрать лучший ракурс, выражение лица и позу, больше не
            нужно переснимать из-за того, что кто-то моргнул или отвернулся, не
            нужно собирать всех вместе и ждать, пока каждый будет готов, каждый
            может выбрать свой стиль и настроение для фото. С помощью
            профессиональной постобработки создаем гармоничную групповую
            фотографию, где все будут выглядеть идеально.
          </h2>
          {/* <h2>{stateTimer01}</h2> */}
        </div>
        <div className={scene01}></div>
        <div className="Description">
          <h1>Запечатлите яркие моменты вашего праздника в стильных кадрах!</h1>
          <h2>
            Пусть эмоции, улыбки и атмосфера торжества останутся с вами
            навсегда! Наша профессиональная фотосъемка на празднике — это:
            Искусство в каждом кадре: создадим настоящие шедевры, подчеркивая
            вашу индивидуальность и настроение вечера. Комфорт и легкость:
            работаю ненавязчиво, не нарушая атмосферу вашего мероприятия, но
            успевая поймать каждый важный момент. Эксклюзивные кадры: стильные
            портреты, искренние эмоции гостей и детали праздника, которые делают
            его уникальным.
          </h2>
        </div>
      </div>

      <div className="Celebration-Galleria">
        <PhotoGalleria
          galleria={galleriaCelebration.galleria[0]}
          photoIcon={galleriaCelebration.photoIcon[0]}
        />
      </div>
    </div>
  )
}
